<script>
	/**
	 * Auth component
	 */
	export default {
		data() {
			return {};
		},
	};
</script>

<template>
	<div>
		<div class="account-pages mt-5 mb-5">
			<div class="container">
				<slot />
			</div>
			<!-- end container -->
		</div>
		<!-- end page -->
		<footer class="footer footer-alt">
			<img src="@/assets/images/wonder-version-2-logo.png" alt height="30" />
			Wnder by Black Sigma Technologies
		</footer>
	</div>
</template>
